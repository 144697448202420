import './App.css'
// import background from './Assets/pattern.png'
// import logo from './Assets/sdLogo-superFaded.png'

function App() {
  return (
    <div className='App'>
      <div className='centerContent'>

      </div>
    </div>
  )
}

export default App
